import axios from "axios";
import {observer} from "mobx-react";
import React, {useState, useMemo, useEffect} from "react";
import {observable, runInAction} from "mobx";
import Input from "antd/es/input";
import {
  Affix,
  Alert, AutoComplete,
  Button,
  Card, Checkbox,
  Col, Drawer,
  Dropdown,
  Empty,
  Menu, message, Popover,
  Row,
  Select,
  Skeleton, Tabs,
  Tooltip
} from "antd";
import ArticleDrawer, {focusStore} from "./ArticleDrawer";
import ArticleStore from "./ArticleStore";
import subMonths from 'date-fns/subMonths'
import { Spin } from 'antd';

import {
  CloseCircleFilled,
  DownOutlined,
  AuditOutlined,
  MenuOutlined, CarryOutOutlined, LeftOutlined, ExperimentTwoTone, CalculatorTwoTone,
} from '@ant-design/icons';

import algoliasearch from 'algoliasearch/lite';
import Space from "antd/es/space";
import Account from "./Account";
import Recent from "./RecentStore";
import CaseTypes from "./CaseTypes";
import Searcher from "./Searcher";

import Logo from '../../assets/images/dinrega-logo.png'
import MMPop from "./MMPop";
import Doc from "./Doc";
import User from "./User";


import UserChannel from "../channels/users_channel"
import VersionsChannel from "../channels/versions_channel"
import ExternalTeamAdmin from "./ExternalTeamAdmin";
import Payment from "./Payment";
import VisibilitySensor from "react-visibility-sensor/visibility-sensor";
import {courtAbbr} from "./Courts";
import HtmlDiv from "./HtmlDiv";
import {callWithCatch, pp} from "./utils";
import MyDropdown from "./MyDropdown";
import {BriefDrawer, BriefDrawerStore} from "./BriefDrawer";
import {addRoute} from "./Router";
import {LawDrawer, LawDrawerStore} from "./LawDrawer";
import Contact from "../screens/Contact";
import FakeTable from "./FakeTable";
import Device from "./Device";
import Calculators from "../admin/Calculators";
import {SearchBotStore, SearchBotSummary} from "./SearchBot";
import {TabBar} from "antd-mobile";
import { Bot, GraduationCap, Logs } from 'lucide-react';
import Switcher from "./Switcher";
import posthog from "posthog-js";
import BriefGen from "./BriefGen.js";

const searchClient = algoliasearch('OKPQ394LEA', '029a628a8e97effbac7ed51773fd0622', {
  headers: {
    'X-Algolia-UserToken': gon.user && gon.user.id
  }
});

let timeFilters = [
  [0, "כל השנים"],
  [Math.floor(subMonths(Date.now(), 5).getTime()/1000), "החודשים האחרונים"],
  [Math.floor(subMonths(Date.now(), 12).getTime()/1000), "השנה האחרונה"],
  [Math.floor(subMonths(Date.now(), 60).getTime()/1000), "5 השנים האחרונות"],
];

let admin = gon.user && gon.user.plan === "admin";
let allCourts = admin || (gon.user && gon.user.plan === "all_courts");

if (gon.user) {
  UserChannel();
  User.cid();
  // VersionsChannel();

  // if (!import.meta.env.DEV) {
    posthog.init('phc_8WgjYPntHWfPQVpXVZ68RZZ9qWZNxLkzkavGmlmx7Su',
      {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
      }
    )
    posthog.identify(
      import.meta.env.DEV ? "dev" : gon.user.id+"",
      gon.user
    );
  // }
}

let decisionFilters = [
  "החלטה",
  "פסק דין",
  "גזר דין",
  "הכרעת דין",
]

let courtFilters = [
  ["all-e", "כל בתי הדין לעבודה", [2, 3, 10, 15, 16, 17, 18, 19, 20, 21, 30]],
  ["national-e", "ארצי", [2]],
  ["regional-e", "אזורי", [3, 10, 15, 16, 17, 18, 19, 20, 21, 30]],
];

if (User.isRabniFam()) {
  courtFilters = [
    ["all-rf", "רבני/משפחה", [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83]],
    ["rabani", "רבני", [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81]],
    ["family", "משפחה", [83]],
  ];
}

if (allCourts) {
  courtFilters = [
    ["all", "כל הערכאות"],
    ["all-e", "כל בתי הדין לעבודה", [2, 3, 10, 15, 16, 17, 18, 19, 20, 21, 30]],
    ["national-e", "ארצי לעבודה", [2]],
    ["regional-e", "אזורי לעבודה", [3, 10, 15, 16, 17, 18, 19, 20, 21, 30]],
    ["supreme", "עליון", [1]],
    ["regional", "מחוזי", [4, 5, 7, 8, 22, 27, 28]],
    ["city", "שלום", [6, 9, 11, 12, 13, 14, 23, 24, 25, 26, 29, 31, 32, 33, 34, 35, 37, 38, 39, 41, 43, 45, 46, 47, 48, 50]],
    ["rabani", "רבני", [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81]],
    ["family", "משפחה", [83]],
  ]
}

let switchToAll = () => {
  store.courtFilter = "all";
  search();
}

let mobile = window.innerWidth < 700;
let wideScreen = window.innerWidth > 1200;

export const store = observable({
  parts: [],
  term: "",
  updatedTerm: "",
  altTerm: null,
  ac: [],
  hits: [],
  nbHits: 0,
  dev: false,
  articles: {},
  pages: 0,
  page: 0,
  search: null,
  moreLoading: false,
  prices: false,
  decisionFilter: false,
  caseTypeFilter: false,
  caseGroupFilter: "all",
  adminFilter: false,
  caseTypes: false,
  timeFilter: 0,
  openSummary: false,
  courtFilter: allCourts ? "all" : ((User.isRabniFam() ? "all-rf" : "all-e")),
  popup: false,
  onlySkira: false,
  onlyHalacha: false,
  advSearch: false,
  searchType: 'psika',
  subScreen: "docs",
  screen: import.meta.env.DEV ? "search" : "search",
  setTerm: t => {
    store.term = t;
    store.altTerm = null;
  }
});

const ArticleDate = React.memo(({date}) => <Tooltip title={date}>
  <span>({date.replace(/\d+\/\d+\//, "")})</span>
</Tooltip>);

let addSearch = () => Recent.addSearch(store.term, { courtFilter: store.courtFilter, caseTypeFilter: store.caseTypeFilter, timeFilter: store.timeFilter });

let loadArticle = async (id, locate) => {
  if (gon.user.status === 'pay1' || gon.user.status === 'pay2') {
    User.track('payment.details')
    store.pricing = true;
    return;
  }

  User.track('doc.open')

  addRoute({aid: id},null, "/docs/" + id)

  ArticleStore.loading = true;

  let params = {};
  let data = (await axios.get("/articles/" + id, { params })).data;
  if (data.cid)
    User.setCid(data.cid);

  ArticleStore.load(data, locate);

  Recent.addDoc(data);
  addSearch();

  if (store.articles[id])
    store.articles[id].viewed_at = "היום";
}

const HitOutcome = ({doc}) => {
  if (mobile || !doc || !doc.outcome || !doc.outcome.length)
    return null;

  let [show, setShow] = useState(false);

  let openSummary = e => {
    e.stopPropagation();
    e.preventDefault();
    ArticleStore.openSummary = true;
    loadArticle(doc.id);
    setShow(false);
    User.track('outcome.summary')
    return false;
  }

  let openPopup = e => {
    e.preventDefault();
    setShow(true);
    User.track('outcome.show')
  }
  let close = () => setShow(false);

  let outcomeContent = <div style={{width: "500px"}}>
    {doc.outcome.map(o => <p key={o["line"]}>{o["line"]}</p>)}
  </div>;

  let title = <div>
    <span>תוצאה</span>
    <a style={{marginRight: "0.5rem", fontSize: "0.75rem"}} className="hit-result-link" href="#" onClick={openSummary}>לתקציר המלא</a>
  </div>

  return <Popover onVisibleChange={close} visible={show} onClick={openPopup} placement="left" content={outcomeContent} title={title}><span className="hit-result-link">תוצאה</span></Popover>
}

const LawHit = observer(({ hit }) => {
  let clickLine = e => {
    let {target} = e;

    LawDrawerStore.load(hit.id, target.innerText);
    addRoute({law: hit.id},null, "/laws/" + hit.id);
  }

  let clickTitle = e => {
    LawDrawerStore.load(hit.id);
    addRoute({law: hit.id},null, "/laws/" + hit.id);
  }

  return <div className="law-result">
    <div className="hit-result-title hover-link" onClick={clickTitle}><b>{hit.title}</b></div>
    {hit.best.map((b, i) => <HtmlDiv key={i} onClick={clickLine} className="mark-focus hover-link doc-line" html={b} />)}
  </div>
})

const BriefHit = observer(({ hit, mention }) => {
  let click = (sym) => {
    // utils.copy(hit.marked_str.replace(/<.+?>/g, ""));

    if (gon.user.status === 'pay1' || gon.user.status === 'pay2') {
      User.track('payment.details')
      store.pricing = true;
      return;
    }

    BriefDrawerStore.load(hit.id, sym);
    addRoute({bid: hit.id},null, "/briefs/" + hit.id)
  }

  let sectionTitle = sym => {
    return hit.titles[sym === "claims" ? 0 : 1];
  }

  let section = (sym) => {
    if(!hit[sym] || !hit[sym].length)
      return null;

    return <div style={{width: "48%", marginLeft: "2%"}} onClick={() => click(sym)}>
      <div className="hit-desc hover-link">{sectionTitle(sym)}</div>
      <HtmlDiv onClick={click} className="mark-focus hover-link" html={hit[sym]} />
    </div>
  }

  return <div className="brief-result">
    <div className="hit-result-title hover-link" onClick={() => click('claims')}>{hit.title}</div>
    <div className="horizontal-flex">
      {section("claims")}
      {section("responses")}
    </div>
  </div>
})

const Hit = observer(({ hit, mention }) => {
  let id = hit.doc_id || hit.objectID;

  let click = async e => {
    if (window.getSelection().toString().length)
      return;

    let el = e.target;
    if (el.tagName === "svg")
      el = el.parentElement.parentElement.querySelector("p");
    if (el.className.match(/mark/))
      el = el.parentElement;
    let locate = _.maxBy(el.textContent.split(/[\n\r]+/), 'length');

    loadArticle(id, locate);
  };

  let titleClick  = async () => {
    loadArticle(id);
  };

  let inner;
  let article = store.articles[id];

  // hack to bypass setting up a "loader"
  if (article === "loading")
    article = null;

  let summary = article ? article.summary : (hit.summary && hit.summary.replace(/ZZ/g, '"'));

  if (article && store.search !== "loading") {
    if (article.marked_summary)
      summary = <div className="mark-focus" key={store.term} dangerouslySetInnerHTML={{__html: article.marked_summary}} />

    if (store.adminFilter && !article.marked_strs.length)
      return null;

    let strs = article.marked_strs.map(s => s.replace(/\[footnoteRef:\d+]/, ""));
    let lines = strs.map(l => <p className="doc-line" key={l} dangerouslySetInnerHTML={{__html: l}} />)
    if (lines.length)
      inner = <div className="mark-focus" key={store.term}>
        {lines}
      </div>;
    else
      inner = <div className="ais-Snippet-highlighted" dangerouslySetInnerHTML={{__html: hit._snippetResult.text.value.replace(/ZZ/g, '"')}} style={{marginBottom: "0.5rem"}}/>;
  }
  else
    inner = <Skeleton active />;

  let date = hit.date ? <ArticleDate date={hit.date} />: "";

  let skira = hit.kind === 2;
  let court = (skira || !hit.court_id) ? null : courtAbbr(hit.court_id);
  let titlePrefix = skira ? " רציו של " : "";
  let title = titlePrefix + hit.case_title.replace(/ZZ/g, '"');

  let case_num = hit.case_num;

  if (gon.user.status === "pay2") {
    case_num = case_num.replace(/\d/g, "?");
    title = title.replace(/["0-9a-zא-ת]/ig, "?");
  }

  let doc = new Doc({title, date: hit.date, court, type: hit.case_type, num: case_num});

  let visibilityChange = async v => {
    if (!v || store.articles[id])
      return;

    store.articles[id] = 'loading';

    let data = (await axios.get("/articles", { params: {ids: id, term: store.altTerm || store.term, admin_filter: store.adminFilter}})).data;

    // in case of an error when there is no doc, keep it as "loading" forever
    store.articles[id] = data.articles[0] || "loading";
  }

  let briefStatus = {
    good: "טענות: טוב",
    bad: "טענות: רע",
    viewed: "טענות: נצפה",
    editing: "טענות: מכין",
    done: "טענות: מוכן",
    ai_build: "טענות: במ",
    ai_done: "טענות: במ מוכן",
  }[article && article.brief_status];

  let viewedAt = article && article.viewed_at;
  let viewedAtTag;

  let css = "result"
  if (viewedAt) {
    css += " doc-viewed";

    viewedAtTag = <Tooltip title={["נצפה בתאריך: ", viewedAt.split(/-/).reverse().join("/")]}>
      <span className="viewed-at-tag">
        <CarryOutOutlined />
      </span>
    </Tooltip>
  }

  return <div className={css}>
    <VisibilitySensor onChange={visibilityChange} active={!store.articles[id]}>
    <div>
      {!mention && <div className="hit-desc" style={{fontSize: "12px", color: "#9CA3AF", display: "flex", flexDirection: "row"}}>
        <span><MMPop disabled={skira || !admin} doc={doc} placement="right" asClick>{case_num} {date}</MMPop> &#8226; {hit.case_type} &#8226; {court}</span>
        { admin && briefStatus && <div style={{marginRight: "0.25rem"}}> &#8226; <span style={{color: article.brief_status === 'good' ? 'blue' : 'red'}}>{briefStatus}</span></div> }
        <HitOutcome doc={article} />
      </div> }

      { !mention && <div style={{cursor: "pointer"}}>
        <div className="hit-result-title" onClick={titleClick}>{skira && <AuditOutlined />}{title} {viewedAtTag}</div>
        { summary && <div onClick={titleClick} style={{fontSize: "16px", color: "#9CA3AF", borderRight: "5px solid #E5E7EB", paddingRight: "4px"}}>{summary}</div> }
      </div> }
    </div>
    </VisibilitySensor>
    <div style={{cursor: "pointer"}} onClick={click}>
      <div>{inner}</div>
    </div>
  </div>
});

const Hits = observer(() => {
  let list;

  list = store.hits;

  let onChange = v => {
    if (!v)
      return;

    store.moreLoading = true;
    addSearch();
    search(true);
  }

  let more;
  if (store.page < store.pages-1)
    more = <VisibilitySensor onChange={onChange} active={!store.moreLoading}>
      <Spin />
    </VisibilitySensor>

  if (store.searchType === "psika") {
    let botDisabled = !SearchBotStore.uuid;
    let setSubScreen = ss => {
      if (!(ss === "bot" && botDisabled))
        store.subScreen = ss;

      if (Device.xs)
        document.body.scrollTop = 0;
    }

    let botColor = botDisabled ? "#d1d5db" : 'black';

    let aititle = "סיכום AI (בטא)"

    if (Device.xs)
      return <Card>
        <Switcher v={store.subScreen} vals={['docs', 'bot', 'help']}>
          <div>
            {list.map(h => <Hit hit={h} key={h.objectID} />)}
            {more}
          </div>
          <SearchBotSummary />
          <div>{store.subScreen === 'help' && <ExtraSearchSuggestions opened={true}/>}</div>
        </Switcher>
        <Affix offsetBottom={0}>
          <div  style={{width: "100%", backgroundColor: 'white', borderTop: "1px solid gray", paddingTop: "0.5rem"}}>
            <TabBar onChange={setSubScreen} activeKey={store.subScreen}>
              <TabBar.Item key="docs" title="תוצאות חיפוש" icon={(active) => <Logs color={active ? '#3E82F7' : 'black'}/>}/>
              <TabBar.Item style={{color: botColor}} key="bot" title={aititle} icon={(active) => <Bot color={active ? '#3E82F7' : botColor}/>}/>
              {/*<TabBar.Item key="help" title="עזרה בניסוח החיפוש" icon={(active) => <GraduationCap color={active ? '#3E82F7' : 'black'}/>}/>*/}
            </TabBar>
          </div>
        </Affix>
      </Card>

    aititle += " של תוצאות החיפוש"
    let iconCardTitle = (icon, title) => <Space>
      {icon}
      <span>{title}</span>
    </Space>

    return <div>
      <Row gutter={32}>
        <Col span={14}>
          <Card size="small" title={iconCardTitle(<Logs />, "תוצאות חיפוש")}>
            {list.map(h => <Hit hit={h} key={h.objectID} />)}
            {more}
          </Card>
        </Col>
        <Col span={10}>
          <Card size="small" title={iconCardTitle(<Bot />, aititle)}><SearchBotSummary /></Card>
        </Col>
      </Row>
    </div>
  }

  return <Card>
    {store.searchType === "briefs" && list.map(h => <BriefHit hit={h} key={h.id} />)}
    {store.searchType === "laws" && list.map(h => <LawHit hit={h} key={h.id} />)}
    {more}
  </Card>;
});

const CourtFilters = observer(({}) => {
  let click = (e) => {
    store.courtFilter = e.key;

    if (store.search)
      search();
  }

  const menu = (
    <Menu onClick={click} items={courtFilters.map(cf => ({label: cf[1], key: cf[0]}))} />
  );

  let primary;
  if (allCourts)
    primary = (store.courtFilter !== 'all');
  else
  if (User.isRabniFam())
    primary = (store.courtFilter !== 'all-rf');
  else
    primary = (store.courtFilter !== 'all-e');

  return <Space>
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type={primary && "primary"}>
        {_.find(courtFilters, cf => cf[0] === store.courtFilter)[1]} <DownOutlined />
      </Button>
    </Dropdown>
  </Space>
});

let caseTypeVals = () => {
  if (store.caseTypes)
    return _.sortBy(_.uniqBy(_.compact(store.caseTypes.map(ct => CaseTypes.find(ct)), 'sym')), 'sym');
  else
    return CaseTypes.sorted();
}

const CaseTypesPopup = observer(({}) => {
  if (!store.popup)
    return;

  let choose = (ct) => {
    store.caseTypeFilter = ct && ct.algolia;
    store.popup = false;

    if (store.search)
      search();
  }

  let close = () => store.popup = false;

  let header =
    <div className="horizontal-flex" style={{justifyContent: "space-between", alignContent: 'center'}}>
      <span>בחרו סוג הליך</span>
      { store.caseTypeFilter && <Button onClick={() => choose(false)}>כל ההליכים</Button> }
    </div>

  return <Drawer
    bodyStyle={{padding: 0}}
    title={header}
    visible={true}
    placement="left"
    onClose={close}
  >
    <FakeTable small objs={caseTypeVals()} td={ct => {
      return <div onClick={() => choose(ct)} className="horizontal-flex" style={{justifyContent: "space-between", paddingBottom: "0.25rem"}}>
        <span>{ct.sym}</span>
        <Space>
          <span>{ct.title}</span>
          <LeftOutlined />
        </Space>
      </div>
    }} />
  </Drawer>
});

const DecisionFilters = observer(({}) => {
  if (!admin)
    return null;

  let onClick = ({key}) => {
    if (key === 'false')
      store.decisionFilter = null;
    else
      store.decisionFilter = key;

    if (store.search)
      search();
  }

  return <MyDropdown def="כל הסוגים" onClick={onClick} options={decisionFilters} placement="top" v={store.decisionFilter}/>
});

const BriefGenTab = observer(({}) => {

})

const AdminFilters = observer(({}) => {
  if (!admin)
    return null;

  let onClick = ({key}) => {
    if (key === 'false')
      store.adminFilter = null;
    else
      store.adminFilter = key;

    if (store.search)
      search();
  }

  return <MyDropdown def="אדמין" onClick={onClick} options={["start", "refs", "all-refs"]} placement="top" v={store.adminFilter}/>
});

const CaseTypeFilters = observer(({}) => {
  let vals = caseTypeVals();

  let options = vals.map(ct => <Select.Option key={ct.sym} value={ct.algolia} label={ct.sym} title={ct.title} className="case-type-option"><span>{ct.sym}</span><span>{ct.title}</span></Select.Option>);

  let onChange = (e) => {
    store.caseTypeFilter = e ? e : false;

    if (store.search)
      search();
  }

  let chooseCaseType = () => {
    store.popup = true;
  }

  let selected = store.caseTypeFilter || store.caseGroupFilter !== "all";

  if (mobile) {
    if (store.caseTypeFilter)
      return <Button onClick={chooseCaseType} type="primary">{CaseTypes.find(store.caseTypeFilter).sym}</Button>

    return <Button type={selected ? "primary" : null} onClick={chooseCaseType}>כל ההליכים</Button>
  }

  return <Select
    className={`case-type-filter ` + (selected && "case-type-filter-selected")}
    style={{ width: 350 }}
    type="primary"
    allowClear
    // labelInValue
    optionLabelProp="label"
    value={store.caseTypeFilter || undefined}
    showSearch
    // disabled={store.caseTypes.length === 0}
    placeholder="כל ההליכים"
    optionFilterProp="children"
    notFoundContent="אין תוצאות"
    onChange={onChange}
    filterOption={(input, option) =>
      option.value.replace(/"/g, '').indexOf(input.replace(/"/g, '')) >= 0 || option.title.indexOf((input)) >= 0
    }
  >
    {options}
  </Select>;
});


const HalachaFilter = observer(({}) => {
  let onChange = () => {
    store.onlyHalacha = !store.onlyHalacha;
    search();
  }

  return <Checkbox style={{marginTop: "5px", margin: mobile && "-2px 10px 5px 0"}} onChange={onChange} checked={store.onlyHalacha}>רק הלכות</Checkbox>
})

const AdvSearchToggle = observer(({}) => {
  let onChange = () => {
    store.advSearch = !store.advSearch;
    search();
  }

  return <Checkbox style={{marginTop: "5px", margin: mobile && "-2px 10px 5px 0"}} onChange={onChange} checked={store.advSearch}>בוט2</Checkbox>
})

const TimeFilters = observer(({}) => {
  let click = (e) => {
    store.timeFilter = parseInt(e.key);

    if (store.search)
      search();
  }

  const menu = (
    <Menu onClick={click} items={timeFilters.map(tf => ({label: tf[1], key: tf[0]}))}/>
  );

  return <Space>
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type={store.timeFilter !== 0 && "primary"}>
        {_.find(timeFilters, tf => tf[0] === store.timeFilter)[1]} <DownOutlined />
      </Button>
    </Dropdown>
  </Space>
});

const CaseGroupFilters = observer(({}) => {
  let click = (e) => {
    store.caseGroupFilter = e.key;
    store.caseTypeFilter = false;

    if (store.search)
      search();
    else {
      if (store.caseGroupFilter === "all")
        store.caseTypes = false;
      else
        store.caseTypes = CaseTypes.groupList(store.caseGroupFilter);
    }
  }

  let groups = _.flatten(["all", CaseTypes.groups().map(ctg => ctg.name)]);

  let label = ctg => ctg === "all" ? "כל התחומים" : ctg;

  const menu = (
    <Menu onClick={click} items={groups.map(ctg => ({label: label(ctg), key: ctg}))}/>
  );

  return <Space>
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type={store.caseGroupFilter !== "all" && "primary"}>
        {label(store.caseGroupFilter)} <DownOutlined />
      </Button>
    </Dropdown>
  </Space>
});

const SearchBox = observer(() => {
  const index = searchClient.initIndex("dr_production_ac");

  let close = () => store.ac = [];

  let setTerm = t => {
    store.setTerm(t || "");

    if (!t || t.length < 2) {
      store.nbHits = 0;
      store.hits = [];
      store.search = null;
      store.caseTypes = false;
    }
  };

  const renderTitle = () => (
    <div className="horizontal-flex-between">
      <span>הצעות לחיפוש</span>
      <span><a href="#" onClick={close}>סגור</a></span>
    </div>
  );

  let runAc = async () => {
    let v = store.term;
    if (v && v.length > 1) {
      let params = {
        hitsPerPage: mobile ? 6 : 6
      };

      if (store.claimsSearch)
        params.filters = "len < 25";

      let result = await index.search(v, params);

      if (result.hits.length)
        // store.ac = _.flatten([{label: "חפש: " + v, value: "search", term: v}, result.hits.map(h => ({ label: h.test, value: h.text }))]);
        store.ac = [{
          title: "",
          label: renderTitle(),
          options: result.hits.map(h => ({ label: h.test, value: h.text }))
        }];
      else {
        store.ac = [];
      }
    }
    else {
      store.hits = [];
      store.ac = [];
    }
  };

  let runSearch = () => {
    store.ac = [];
    search();
  }

  let onAcSelect = (v, o) => {
    // comes from 'enter' while selecting a row, already did onSearch
    if (store.search === "searching")
      return;

    setTerm(o.term || o.value);
    runSearch();
    User.track("search.autocomplete");
  }

  const debounceAC = useMemo(
    () => _.debounce(runAc, (store.term.length > 8 && store.ac.length === 0) ? 500 : 250)
    , []);

  let onChange = v => {
    // let v = event.currentTarget.value;

    setTerm(v);
    debounceAC();
  };

  let onSearch = e => {
    if (e.length) {
      if (store.term && store.term.length > 1) {
        store.ac = [];
        runSearch();
        User.track("search");
      } else {
        message.warning("נא לכתוב בשורת החיפוש");
      }
    }
    else
      store.hits = [];
  }

  return <div id="search-box" style={{maxWidth: "800px"}}>
    <AutoComplete
      popupClassName="ac-search"
      options={store.ac}
      type="search"
      autoFocus={true}
      value={store.term}
      onChange={onChange}
      onSelect={onAcSelect}
    >
      <Input.Search enterButton allowClear placeholder="מה לחפש?" onSearch={onSearch} size="large"/>
    </AutoComplete>
  </div>
});

const ExtraSearchSuggestions = observer(({opened}) => {
  const [open, setOpen] = useState(opened);
  const [lastTerm, setLastTerm] = useState(false);
  const [suggestions, setSuggestions] = useState(false);

  let loadSearch = t => {
    setOpen(false);
    store.setTerm(t);

    search();
  }

  const handleOpenChange = async (newOpen) => {
    setOpen(newOpen);

    if (lastTerm === store.term)
      return;
    else
      setSuggestions(false);

    setLastTerm(store.term);
    callWithCatch({
      url: '/searches/suggestions',
      method: 'post',
      params: {q: store.term},
      onSuccess: data => {
        setSuggestions(data.suggestions);
      },
      onErr: () => {
        setSuggestions(false);
      }
    })
  };

  if (opened)
    useEffect(() => handleOpenChange(true), []);

  let content;
  if (suggestions)
    content = <table className="search-examples">
      <tbody>
      {suggestions.map(r => <tr key={r}><td><a onClick={() => loadSearch(r)}>{r}</a></td></tr>)}
      </tbody>
    </table>
  else
    content = <Spin />

  if (opened)
    return content;

  return (
    <Popover
      overlayInnerStyle={{width: Device.xs ? "100vw" : "400px"}}
      content={content}
      title="הצעות חיפוש"
      trigger="click"
      visible={open}
      placement="bottomRight"
      onVisibleChange={handleOpenChange}
    >
      <Button type="primary" style={{marginBottom: "8px", marginRight: Device.xs && "8px"}}>עזרה בניסוח החיפוש שלך</Button>
    </Popover>
  );
});

const TopBar = observer(() => {
  let handleMenuClick = e => {
    if (e.key === "logout")
      window.location.href = "/logout"

    if (e.key === "accessibility")
      window.open("/accessibility", '_blank')

    if (e.key === "pricing")
      store.pricing = true;

    if (e.key === "music") {
      let audio = new Audio('/bg-audio.m4a');
      message.success("מתחיל לנגן...", 1);
      _.delay(() => {
        audio.play();
      }, 2000);
    }

    if (e.key === "team")
      store.screen = "team";

    if (e.key === "calculators")
      store.screen = "calculators";

    if (e.key === "briefgen")
      store.screen = "briefgen";

    if (e.key === "contact")
      store.screen = "contact";

    if (e.key === "claims_beta") {
      store.search = null;
      store.setTerm("");
      store.pages = store.page = 0;
      store.claimsSearch = !store.claimsSearch;
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="name" disabled>{gon.user.name}</Menu.Item>
      <Menu.Item key="contact">צור קשר</Menu.Item>
      {!User.isPaying() && <Menu.Item key="pricing">מחירון</Menu.Item>}
      {gon.user.status === "team_admin" && <Menu.Item key="team">משתמשים</Menu.Item>}
      {(admin || User.tester()) && <Menu.Item key="music">מוזיקה</Menu.Item>}
      <Menu.Item key="accessibility">נגישות ♿</Menu.Item>
      <Menu.Item key="logout">התנתקות</Menu.Item>
    </Menu>
  );

  let suffix = <Space>
    <Dropdown overlay={menu} placement="bottomLeft" arrow trigger="click">
      {mobile ? <Button icon={<MenuOutlined/>}/> : <Button>תפריט</Button>}
    </Dropdown>
  </Space>;

  let changeSearchType = st => {
    if (st === "calculators")
      store.screen = "calculators";
    else if (st === "briefgen")
      store.screen = "briefgen";
    else {
      store.screen = "search";

      runInAction(() => {
        store.hits = [];
        store.searchType = st;
        search();
      })
    }
  }

  let searchType = <Tabs
    className="search-type"
    defaultActiveKey="psika"
    value={store.searchType}
    onChange={changeSearchType}
    // tabBarExtraContent={<ExtraSearchSuggestions />}
    >
    <Tabs.TabPane tab="פסיקה" key="psika" />
    <Tabs.TabPane tab="חקיקה" key="laws" />
    <Tabs.TabPane tab="כתבי טענות" key="briefs" />
    <Tabs.TabPane tab="מחשבונים" key="calculators" />
    { User.isAdmin() && <Tabs.TabPane key="briefgen" tab={<span><span>מחולל כתבי טענות</span> <ExperimentTwoTone twoToneColor="#4ade80" /></span>} /> }
    { User.isAdmin() && <Tabs.TabPane tab="אדמין" key="admin" /> }
  </Tabs>

  let isCalculators = store.screen === "calculators";

  return <div style={{maxWidth: "992px", margin: "0 auto"}}>
    <div className="horizontal-flex" style={{justifyContent: 'space-between'}}>
      {!User.isClosed() && !isCalculators && <SearchBox /> }
      { isCalculators && <div style={{height: "47px", width: "20px"}}> </div>}
      {suffix}
    </div>
    <div>{searchType}</div>
  </div>
});

const Examples = observer(() => {
  if (store.searchType !== "psika" || store.search)
    return null;

  let examples = [
    ["חיפוש לפי מילות מפתח", "ענישה מקלה בעבירות החזקת סכין נאשם ללא עבר פלילי"],
    ["אזכורי סעיפי חוק", "סעיף 117 (ב)(1) לחוק מס ערך מוסף"],
    ["חיתוכים (סעיף חוק + סוגיה משפטית)", "סעיף 49 לחוק הירושה יורש ללא צאצאים שנפטר לפני המוריש"],
    ["חיתוכים (סעיף חוק + סוגיה משפטית + שופט)", "סעיף 144 לתקנות סד\"א החדשות צירוף ראיות בערעור השופטת אביגיל כהן"],
    ["סיטואציה משפטית כללית", "מימוש משכנתא מדרגה שניה על בית מגורים"],
    ["סיטואציה ספציפית", "עובד שמבקש מהמעסיק שייתן לו מכתב פיטורים כדי לקבל דמי אבטלה מביטוח לאומי"],
    ["שאלה משפטית", "האם נפילה לבור ביציאה מהרכב נחשבת תאונת דרכים"],
  ]

  if (mobile)
    examples = [examples[0], examples[1], examples[3]];

  return <Card title='איך משתמשים במנוע החיפוש של דין רגע?' style={{marginTop: "1rem"}}>
    <div>מהפכת הבינה המלאכותית מאפשרת את כל סוגי החיפוש בהקלדה חופשית!</div>
    <div>דוגמאות:</div>
    <br/>
    <table className="search-examples">
      <tbody>
      {examples.map(r => <tr key={r[0]}><td>{r[0]}</td><td>{Searcher.link(r[1])}</td></tr>)}
      </tbody>
    </table>
  </Card>
})

const EmptyState = observer(() => {
  if (store.search === "no-results") {
    let desc = "אין תוצאות"
    return <Card>
      <Empty description={<b>{desc}</b>}>
        { allCourts && store.term.length > 2 && store.courtFilter !== "all" && <Button type="primary" onClick={switchToAll}>חפשו בכל הערכאות</Button> }
        <ExtraSearchSuggestions />
      </Empty>
    </Card>
  }
});

const RecentLine = ({onClick, title, onRemove}) => {
  let click = e => {
    e.preventDefault();
    onClick();
  }

  return <div className="recent-line">
    <a href="#" type="link" onClick={click} title={title}>{title}</a>
    <CloseCircleFilled onClick={onRemove} />
  </div>
}

const RecentBox = observer(() => {
  if (store.search === "searching" || store.search === "searching-next" || store.search === "results" || Recent.isEmpty())
    return null;

  let loadSearch = t => {
    store.setTerm(t.term);
    store.courtFilter = t.courtFilter;

    store.timeFilter = t.timeFilter || 0;
    store.caseTypeFilter = t.caseTypeFilter || false;

    search();
  }

  return <Row gutter={16}>
    <Col xs={24} xl={12}>
      <Card title="חיפושים אחרונים">
        {Recent.searches.map(t => <RecentLine key={t.term} onClick={() => loadSearch(t)} onRemove={() => Recent.removeSearch(t.term)} title={t.term} />)}
      </Card>
      <br/>
    </Col>
    <Col xs={24} xl={12}>
      <Card title="מסמכים אחרונים">
        {Recent.docs.map(t => <RecentLine key={t.id} onClick={e => loadArticle(t.id)} onRemove={() => Recent.removeDoc(t)} title={t.case_title} />)}
      </Card>
    </Col>
  </Row>
});

const ResultsNum = ({num}) => {
  let str;

  if (num > 0)
    str = num + " " + "תוצאות";

  if (num === 1)
    str = "תוצאה אחת";

  if (!admin) {
    if (num > 100)
      str = "מעל 100 תוצאות";

    if (num > 20 && store.searchType === 'briefs')
      str = "מעל 20 תוצאות";
  }

  if (store.search === "searching" || store.search === "searching-next")
    str = " ";

  return <div className="results-num">{str}</div>
};

const search = (nextPage) => {
  let term = store.term;

  // if (term && term.split(/ /).length > 17) {
  //   Modal.warning({title: "החיפוש ארוך מידי, אנא מקדו את החיפוש"});
  //   return;
  // }

  store.search = nextPage ? "searching-next" : "searching";
  store.subScreen = "docs";

  if (store.searchType === 'briefs')
    briefsSearch();
  else
    if (store.searchType === 'laws')
      lawsSearch(nextPage);
  else
    psikaSearch(nextPage);
};

const lawsSearch = async (nextPage) => {
  let term = store.term;
  if (term.length < 3) {
    store.search = null;
    store.hits = [];
    return;
  }

  if (!nextPage)
    document.body.scrollTop = 0;

  let data = (await axios.post("/searches", { q: term, laws: true, page: nextPage ? store.page+1 : 0 })).data

  // there was another search
  if (store.searchType !== "laws")
    return null;

  if (nextPage) {
    store.hits = _.concat(store.hits, data.hits);
    store.page += 1;
    store.search = "results";
  }
  else {
    store.sentTerm = data.term;

    store.page = 0;
    store.hits = data.hits;
    store.pages = data.pages;
    store.nbHits = data.nhits;
    store.search = data.hits.length ? "results" : "no-results" ;
  }

  store.moreLoading = false;
}

const briefsSearch = async () => {
  let term = store.term;
  if (term.length < 3) {
    store.search = null;
    store.hits = [];
    return;
  }

  document.body.scrollTop = 0;
  store.pages = store.page = 0;
  let data = (await axios.post("/searches", { q: term, briefs: true, court: store.courtFilter })).data

  // there was another search
  if (store.searchType !== "briefs")
    return null;

  store.sentTerm = data.term;
  store.hits = data.hits;
  store.nbHits = data.hits.length;
  store.search = data.hits.length ? "results" : "no-results" ;
}

const psikaSearch = async (nextPage) => {
  let term = store.term;
  if (term.length < 3) {
    store.search = null;
    store.hits = [];
    return;
  }

  if (!nextPage)
    document.body.scrollTop = 0;

  let data = (await axios.post("/searches", {
    bot3: store.advSearch,
    q: term,
    alt: store.altTerm,
    psika: true,
    page: nextPage ? store.page+1 : 0,
    time: store.timeFilter,
    court: store.courtFilter,
    decision: store.decisionFilter,
    onlyHalacha: store.onlyHalacha,
    onlySkira: store.onlySkira,
    case_type: store.caseTypeFilter,
    case_group: store.caseGroupFilter,
  })).data

  // there was another search
  if (store.searchType !== "psika")
    return null;

  if (nextPage) {
    store.hits = _.concat(store.hits, data.hits);
    store.page += 1;
    store.search = "results";
  }
  else {
    store.articles = {};
    if (data.facets)
      store.caseTypes = _.keys(data.facets.case_type);

    store.sentTerm = data.term;
    store.altTerm = data.alt;

    store.page = 0;
    store.hits = data.hits;
    store.pages = data.pages;
    store.nbHits = data.nhits;
    store.search = data.hits.length ? "results" : "no-results" ;

    if (data.uuid)
      SearchBotStore.open(store, data.uuid);
    else
      SearchBotStore.close();
  }

  store.moreLoading = false;
}

const SearchDrawer = observer(() => <ArticleDrawer term={store.term} dev={store.dev} defMode={store.openSummary} articles={store.articles}/>);

const PayAlert = observer(() => {
  if (!gon.user.status.match(/pay/))
    return;

  let openMenu = () => store.pricing = true;

  let msg = gon.user.name;

  if (gon.user.status === "pay0")
    msg += ", תקופת הנסיון (הפיילוט) שלך עומדת להסתיים - ";
  else
    msg += ", לצפייה במחירון מנויים - ";

  return <Alert style={{width: "100%", marginTop: "1rem", marginBottom: "1rem", cursor: "pointer"}}
                onClick={openMenu}
                message={<span><span>{msg}</span><a href="#" onClick={openMenu}>לחצו כאן לפרטים</a></span>}
                type="warning"
  />
});

const BriefsFAQ = () => {
  if (store.hits.length || store.search === "searching")
    return null;

  let examples = [
    ["האם כתבי הטענות הקיימים במערכת הוגשו לבית המשפט?", "לא, המערכת מהנדסת כתבי טענות מתוך הפסיקה אוטומטית"],
    ["כמה כתבי טענות יש במערכת?", "הפרוייקט מכיל מעל 100,000 כתבי טענות!"],
    ["מדוע תוצאות החיפוש מחולקות ל-2 עמודות?", "ניתן לצפות גם בטענות הנגדיות!", "(כשאחת העמודות ריקה סימן שאין במערכת טענות נגדיות)"],
    ["כתבתי את הביטוי \"תביעה\" - מדוע קיבלתי רק 20 תוצאות?", "אנו מציגים רק את 20 התוצאות הראשונות", "(מומלץ לדייק את החיפוש למשל: \"תביעת נזיקין\")"],
    ["כתב טענות בנושא מסוים שחיפשתי עדיין לא נכנס למערכת, האם יש מה לעשות?", <span>{"כן. ניתן לנסות לשלוח בקשה לדוא\"ל "} <a href="mailto:office@fridman-adv.com">office@fridman-adv.com</a></span>],
  ]

  return <Card title='שאלות נפוצות על פרוייקט כתבי הטענות:'>
    {examples.map(r =>
      <div style={{marginBottom: "1rem"}} key={r[0]}>
        <div><b>{r[0]}</b></div>
        <div>{r[1]}</div>
        <div>{r[2]}</div>
      </div>)}
  </Card>
}

const BriefsExplain = () => {
  if (store.searchType !== "briefs")
    return null;

  return <div>
    <Alert message="המערכת יוצרת מדי יום כתבי טענות חדשים!" />
    <br/>
    {(!store.search || store.search === "searching") && <BriefsFAQ /> }
  </div>
}

const LawsExplain = () => {
  if (store.searchType !== "laws")
    return null;

  return <div>
    <Alert message="חיפוש בתוך חקיקה" />
  </div>
}

export default observer(() => {
  let inner;
  if (store.search === "results" || store.search === "searching-next")
    inner = <Hits />;
  else {
    inner = <EmptyState />;
  }

  if (store.search === "searching")
    inner = <Card><span/> <Spin /></Card>;

  let showVideo = (!store.search || store.search === "no-results") && store.searchType === "psika";
  let video = showVideo && <div>
    <br/>
    <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
      <div className="wistia_responsive_wrapper" style={{height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}}>
        <iframe src={"https://fast.wistia.net/embed/iframe/xonyt7vgte?videoFoam=true&wemail="+gon.user.email} title="הדרכת דין רגע"
                allow="autoplay; fullscreen" frameBorder="0" scrolling="no"
                className="wistia_embed" name="wistia_embed" width="100%" height="100%" />
      </div>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async/>
  </div>

  let filters;

  if (store.searchType === "psika")
    filters = <div className="scrolling-wrapper filters">
      <div className="card2">
        <CourtFilters />
      </div>
      <div className="card2">
        <TimeFilters />
      </div>
      <div className="card2">
        <CaseGroupFilters />
      </div>
      <div className="card2">
        <CaseTypeFilters />
      </div>
      <div className="card2">
        <DecisionFilters />
      </div>
      <div className="card2">
        <AdminFilters />
      </div>
      <div className="card2">
        { !mobile && <HalachaFilter /> }
      </div>
      <div className="card2">
        { admin && <AdvSearchToggle /> }
      </div>
    </div>
  else if (store.searchType === "briefs")
    filters = <div className="scrolling-wrapper filters">
      <div className="card2">
        <CourtFilters />
      </div>
    </div>

  let bottom = <div>
    <div className="search-area">
      { admin && (store.search === "results" || store.search === "no-results") && <div style={{ marginBottom: "0.5rem", color: 'green' }}><span>נשלח בצורה: </span>{store.sentTerm}</div>}
      <Row wrap={false} style={mobile ? { margin: "0.5rem" } : { marginBottom: "1rem"}}>
        {store.claimsSearch && <Alert style={{width: "100%"}} type="info" message="חיפוש בבנק הטענות" /> }
        {!store.claimsSearch && <Col flex="none">
          {filters}
        </Col> }
        <Col flex="auto" />
        <Col flex="none">
          { !mobile && <ResultsNum num={store.nbHits} /> }
        </Col>
      </Row>
      { mobile && <div className="horizontal-flex-between">
          <div>{ store.searchType === "psika" && <HalachaFilter /> }</div>
          <ResultsNum num={store.nbHits} />
        </div> }
      <PayAlert />
      <BriefsExplain />
      <LawsExplain />
      { store.search === "results" && <ExtraSearchSuggestions /> }
      {inner}
      { !mobile && <br/> }
      <RecentBox />
      <Examples />
      {video}
    </div>
  </div>

  if (store.screen === "team")
    bottom = <ExternalTeamAdmin teamId={gon.user.team_id}/>;

  if (store.screen === "calculators")
    bottom = <div>
      <Calculators />
    </div>;

  if (store.screen === "briefgen")
    bottom = <div className="py-8">
      <BriefGen />
    </div>;

  if (store.screen === "contact")
    bottom = <Contact store={store} />;

  if (User.isClosed())
    bottom = <div>
      <Alert style={{width: "100%", margin: "1rem 0"}}
             message="לשימוש באתר, אנא עשו מנוי"
             type="warning"
      />
      <Payment/>
      {video}
    </div>;

  return <div>
    {ArticleStore.loading && <SearchDrawer/>}
    <BriefDrawer loadArticle={loadArticle}/>
    <LawDrawer />
    <CaseTypesPopup/>
    <Account store={store}/>
    <div>
      { !store.popup && (!mobile || !ArticleStore.loading || !BriefDrawerStore.opened || !LawDrawerStore.opened) && <Affix offsetTop={1}>
        <div className="rtl" style={{backgroundColor: '#3E82F7', padding: "0.5rem"}}>
          { wideScreen && <a href="/"><img src={Logo} className="logo" alt="דין רגע"/></a> }
          <TopBar />
        </div>
      </Affix> }
      <div style={{maxWidth: store.screen === "search" ? "1200px" : "992px", margin: "0 auto"}}>
        {bottom}
      </div>
    </div>
  </div>
});

let actOnRoute = () => {
  let {pathname} = window.location;

  if (pathname === "/") {
    ArticleStore.article = null;
    ArticleStore.loading = false;
    BriefDrawerStore.close();
    LawDrawerStore.close();
  }

  ArticleStore.article=null;
  ArticleStore.loading=false;
  ArticleStore.openSummary=false;

  if (pathname.match(/^.docs\/(?:skira-)?\d+$/))
    loadArticle(pathname.replaceAll(/\/docs\//g, ""));

  if (pathname.match(/^.briefs\/\d+$/))
    BriefDrawerStore.load(pathname.replaceAll(/\D/g, ""));

  if (pathname.match(/^.laws\/\d+$/))
    LawDrawerStore.load(pathname.replaceAll(/\D/g, ""));

  // "/" || ""
  // if (pathname.length <= 1)
  //   store.term = "";

  if (pathname.match(/^.search/)) {
    let params = (new URL(document.location)).searchParams;
    let q = params.get('q');

    if (store.term !== q) {
      store.setTerm(q);
      search() ;
    }
  }
}

if (gon.user) {
  window.addEventListener('popstate', actOnRoute);
  actOnRoute();
}
